<template>
  <div class="e-form e-form--register">
    <!-- Title -->
    <div class="e-form__title">
      <h1>{{ $t('system.register.title') }}</h1>
    </div>

    <el-form ref="registerForm" :model="registerForm" :rules="rules" method="post">
      <!-- Errormessages -->
      <el-alert
        v-for="(item, index) in errors"
        :key="index"
        :title="$t(`system.register.${index}`)"
        type="error"
      >
        <div v-for="(message, i) in item" :key="i" v-html="$t(message)" />
      </el-alert>
      <!-- /Errormessages -->

      <!-- Row -->
      <el-form-item
        :label="$t('system.login.username')"
        :class="{
          'e-danger': usernameTaken,
          'e-success': usernameFree
        }"
        prop="username"
      >
        <el-input
          v-model.trim="registerForm.username"
          :placeholder="$t('system.login.username')"
          name="username"
          auto-complete="username"
          :autofocus="true"
          id="username"
        />
        <el-tooltip v-if="usernameTaken" :content="$t('messages.user.username.exists')">
          <IconExclamation />
        </el-tooltip>

        <el-tooltip v-if="usernameFree" :content="$t('system.ok')">
          <IconCheck />
        </el-tooltip>
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.register.gender')" prop="gender" required>
        <el-select v-model="registerForm.gender" :placeholder="$t('system.register.gender')" auto-complete="sex" id="gender">
          <el-option
            v-for="item in genderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!-- Row -->
      <el-form-item
        :label="$t('system.register.counselingCenter')"
        prop="counselingCenter"
        required
      >
        <el-select
          v-model="registerForm.counselingCenter"
          :placeholder="$t('system.register.counselingCenter')"
          @blur="validateCounselingCenter"
          auto-complete="off"
          id="counselingCenter"
        >
          <el-option
            v-for="center in counselingCenters"
            :key="center.name"
            :label="center.name"
            :value="center.name"
          />
        </el-select>
      </el-form-item>

      <!-- Row -->
      <el-form-item
        :label="$t('system.register.yearOfBirth')"
        prop="yearOfBirth"
        required>
        <el-input
          v-model="registerForm.yearOfBirth"
          :placeholder="$t('system.register.yearOfBirth')"
          :min="minYear"
          :max="maxYear"
          type="number"
          name="yearOfBirth"
          auto-complete="bday-year"
          id="yearOfBirth"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.register.password')" prop="password" required>
        <el-input
          v-model="registerForm.password"
          :placeholder="$t('system.register.password')"
          type="password"
          name="password"
          auto-complete="new-password"
          id="password"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.register.repeat')" prop="password2" required>
        <el-input
          v-model="registerForm.password2"
          :placeholder="$t('system.register.passwordRepeat')"
          type="password"
          name="password2"
          auto-complete="off"
          id="password2"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item
        :label="$t('system.register.email')"
        :class="{
          'e-danger': emailExists
        }"
        prop="email"
      >
        <el-input
          v-model.trim="registerForm.email"
          :placeholder="$t('system.register.emailPlaceholder')"
          type="email"
          name="email"
          auto-complete="email"
          id="email"
        />
        <el-tooltip v-if="emailExists" :content="$t('messages.user.email.exists')">
          <IconExclamation />
        </el-tooltip>
      </el-form-item>
      <div v-if="registerForm.email === ''" class="email-additional-info">
        {{ $t(`system.register.emailAdditionalInfo`) }}
      </div>

      <!-- Row -->
      <el-form-item label="" prop="agb" class="el-form-item--checkbox el-form-item--nolabel">
        <label
          :class="{ 'is-checked': registerForm.agb === true }"
          role="checkbox"
          class="el-checkbox"
        >
          <span
            :class="{ 'is-checked': registerForm.agb === true }"
            aria-checked="mixed"
            class="el-checkbox__input"
          >
            <span class="el-checkbox__inner" />
            <input
              v-model="registerForm.agb"
              type="checkbox"
              name="agb"
              class="el-checkbox__original"
              value="true"
              id="agb"
            />
          </span>
          <span class="el-checkbox__label">
            {{ $t('system.register.agb1') }}
            <router-link :to="{ name: 'content', params: { contentName: 'gtou' } }" target="_blank">
              {{ $t('system.navigation.gtou') }}
            </router-link>
            {{ $t('system.register.agb2') }}
          </span>
        </label>
      </el-form-item>
      <!-- Row -->

      <!-- Row -->
      <el-form-item label="" prop="privacy" class="el-form-item--checkbox el-form-item--nolabel">
        <label
          :class="{ 'is-checked': registerForm.privacy === true }"
          role="checkbox"
          class="el-checkbox"
        >
          <span
            :class="{ 'is-checked': registerForm.privacy === true }"
            aria-checked="mixed"
            class="el-checkbox__input"
          >
            <span class="el-checkbox__inner" />
            <input
              v-model="registerForm.privacy"
              type="checkbox"
              name="privacy"
              class="el-checkbox__original"
              value="true"
              id="privacy"
            />
          </span>
          <span class="el-checkbox__label">
            {{ $t('system.register.agb1') }}
            <router-link
              :to="{ name: 'content', params: { contentName: 'privacy' } }"
              target="_blank"
            >
              {{ $t('system.privacy.title') }}
            </router-link>
            {{ $t('system.register.agb2') }}
          </span>
        </label>
      </el-form-item>
      <!-- Row -->

      <!-- Row -->
      <div class="e-form__row e-form__row--submit">
        <router-link
          :to="{ name: 'index', query: $route.query }"
          class="e-btn e-btn--ghost e-btn--large"
        >
          {{ $t('system.back') }}
        </router-link>

        <el-button
          :loading="loading"
          :disabled="!formComplete"
          type="secondary"
          size="large"
          native-type="submit"
          @click.prevent="submitForm('registerForm')"
        >
          {{ $t('system.login.register') }}
        </el-button>
      </div>

      <div class="e-form__help" v-html="$t('messages.user.register.hint')" />
    </el-form>
  </div>
</template>

<script>
import { userActions, errors } from 'mixins'
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

// Icons
import IconExclamation from 'assets/icons/basic-exclamation.svg'
import IconCheck from 'assets/icons/arrows-check.svg'

export default {
  components: {
    IconExclamation,
    IconCheck
  },
  mixins: [errors, userActions],
  data() {
    const checkPassStrength = (value) => {
      // eslint-disable-next-line
      const regex = new RegExp(
        '^(?=.*[A-z])(?=.*[0-9])(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])(?=.{8,})'
      )
      return regex.test(value)
    }

    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.user.password.empty')))
      } else {
        // Check if Password succeeds rules
        if (checkPassStrength(value)) {
          if (this.registerForm.password2 !== '') {
            this.$refs.registerForm.validateField('password2')
          }
          callback()
        } else {
          callback(new Error(this.$t('messages.user.password.invalidShort')))
        }
      }
    }

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.user.password.empty')))
      } else if (value !== this.registerForm.password) {
        callback(new Error(this.$t('messages.user.password.dontFit')))
      } else {
        callback()
      }
    }

    const validateYear = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.user.yearOfBirth.empty')))
      } else if (value !== null && value !== '' && (isNaN(parseFloat(value)) || !isFinite(value))) {
        callback(new Error(this.$t('messages.user.yearOfBirth.numOnly')))
      } else if (value >= new Date().getFullYear()) {
        callback(new Error(this.$t('messages.user.yearOfBirth.noFuture')))
      } else if (value !== null && value !== '' && new Date().getFullYear() - value > 150) {
        callback(new Error(this.$t('messages.user.yearOfBirth.error')))
        // callback()
      } else {
        callback()
      }
    }

    const validateUsername = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error(this.$t('messages.user.username.empty')))
      } else {
        callback()
      }
    }

    const validateGender = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error(this.$t('messages.user.gender.empty')))
      } else {
        callback()
      }
    }

    const validateEmail = (rule, value, callback) => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
      if (value === null || value === '' || re.test(String(value).toLowerCase())) {
        callback()
      } else {
        callback(new Error(this.$t('messages.user.email.invalid')))
      }
    }

    const validateCounselingCenter = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('messages.user.counselingCenter.empty')))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      usernameTaken: false,
      usernameFree: false,
      usernameChecking: false,
      emailExists: false,
      emailChecking: false,
      errors: [],
      registerForm: {
        username: '',
        gender: null,
        counselingCenter: null,
        yearOfBirth: '',
        password: '',
        password2: '',
        email: '',
        agb: false,
        privacy: false,
        pausentaste: new URLSearchParams(window.location.search).get('r') === 'pausentaste'
      },
      rules: {
        username: [{ required: true, validator: validateUsername, trigger: 'blur' }],
        gender: [{ required: true, validator: validateGender, trigger: 'blur' }],
        yearOfBirth: [{ required: false, validator: validateYear, trigger: 'blur' }],
        password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        password2: [{ required: true, validator: validatePass2, trigger: 'blur' }],
        email: [{ required: false, validator: validateEmail, trigger: 'blur' }],
        counselingCenter: [{ required: true, validator: validateCounselingCenter, trigger: 'blur' }]
      },
      error: {
        is: false,
        message: ''
      }
    }
  },
  computed: {
    ...mapGetters(['counselingCenterId', 'language', 'languageCounselingCenterMap']),
    genderOptions() {
      return [
        {
          value: window.genderEnum.GENDER_FEMALE,
          label: this.$t('system.gender.female')
        },
        {
          value: window.genderEnum.GENDER_MALE,
          label: this.$t('system.gender.male')
        },
        {
          value: window.genderEnum.GENDER_DIVERS,
          label: this.$t('system.gender.divers')
        },
        {
          value: window.genderEnum.GENDER_NONE,
          label: this.$t('system.gender.unknown')
        }
      ]
    },
    formComplete() {
      let formComplete = true

      for (const field in this.registerForm) {
        if (
          (this.registerForm[field] === '' || this.registerForm[field] === null) &&
          this.rules[field] &&
          this.rules[field].length &&
          this.rules[field][0].required
        ) {
          formComplete = false
        }
      }

      if (this.registerForm.password !== this.registerForm.password2) formComplete = false
      if (!this.registerForm.agb) formComplete = false
      if (!this.registerForm.privacy) formComplete = false
      if (this.usernameTaken) formComplete = false

      return formComplete
    },

    minYear: () => null, // new Date().getFullYear() - 26,
    maxYear: () => new Date().getFullYear() - 5,
    counselingCenters() {
      return Object.keys(this.languageCounselingCenterMap).map((key) => ({
        id: this.languageCounselingCenterMap[key],
        name: this.$t(`system.languages.${key}`)
      }))
    }
  },
  watch: {
    'registerForm.username': function (newVal, oldVal) {
      // eslint-disable-line
      if (oldVal.length > 0 && newVal.length === 0) {
        this.usernameTaken = false
        this.usernameFree = false
      } else if (!this.usernameChecking && this.registerForm.username.length > 0) {
        this.checkUsernameExists()
      }
    },
    'registerForm.email': function () {
      // eslint-disable-line
      if (!this.emailChecking && this.registerForm.email.length > 0) {
        if (!this.validateMail(this.registerForm.email)) {
          this.$error(this.$t('messages.user.email.invalid'))
          return
        }
        if (this.registerForm.email === '') {
          return true
        }
        this.checkEmailExists(this.registerForm.email)
      }
    }
  },
  async created() {
    if (!(this.counsleingCenters && this.counselingCenters.length)) {
      await this.loadCounselingCenters()
    }
    this.$nextTick(() => {
      if (!this.counselingCenterId) {
        return
      }
      const counselingCenter = this.counselingCenters
        .filter((center) => center.id === this.counselingCenterId)
        .pop()
      if (!counselingCenter) {
        return
      }
      this.registerForm = { ...this.registerForm, counselingCenter: counselingCenter.name }
    })
  },
  /**
   * METHODS
   */
  methods: {
    ...mapActions(['loadCounselingCenters']),
    submitForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.makeRegister(formName)
          } else {
            return false
          }
        })
      }
    },

    async makeRegister(formName) {
      this.loading = true
      const counselingCenterId = this.counselingCenters.filter((cc) => cc.name === this.registerForm.counselingCenter).pop().id
      try {
        await this.__user__registerUser({ ...this[formName], locale: this.language, counselingCenter: counselingCenterId })

        this.$router.push({
          name: 'index',
          query: {
            ...this.$route.query,
            registered: 'true',
            email: this.registerForm.email !== '' ? '1' : '0'
          }
        })
      } catch (error) {
        switch (error.status) {
          case 422:
            this.errors = error.messages
            break
          case 500:
            this.$_errors_handleError500()
            break
          default:
            this.$error(error)
        }
      } finally {
        this.loading = false
      }
    },

    checkUsernameExists: debounce(
      async function () {
        this.usernameFree = false
        this.usernameTaken = false
        this.usernameChecking = true

        try {
          await this.__user__checkUsername(this.registerForm.username)
          this.usernameTaken = true
        } catch (error) {
          switch (error.status) {
            case 404:
              this.usernameFree = true
              break
            case 500:
              this.$_errors_handleError500()
              break
            default:
              this.$error(error)
          }
        } finally {
          this.usernameChecking = false
        }
      },
      300,
      { leading: false, trailing: true }
    ),

    checkEmailExists: debounce(
      async function (email) {
        this.emailExists = false
        this.emailChecking = true

        try {
          await this.__user__checkEmail(email)
          this.emailExists = true
        } catch (error) {
          if (error.status !== 404) {
            this.$error(error.message)
          }
          this.emailExists = false
        } finally {
          this.emailChecking = false
        }
        return this.emailExists
      },
      300,
      { leading: false, trailing: true }
    ),

    validateMail(email) {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return email === null || email === '' || re.test(email)
    },
    validateCounselingCenter() {
      this.$refs.registerForm.validateField('counselingCenter')
    }
  }
}
</script>

<style lang="scss">
.e-form .el-form-item--nolabel {
  height: 4.2rem;
}
</style>
